"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPropertyValue = void 0;
const getPropertyValue = (product, key) => {
    let propertyValue = '-';
    let found = false;
    product.publicProperties.forEach(prop => {
        if (prop.key === key) {
            propertyValue = prop.value;
            found = true;
        }
    });
    if (!found) {
        product.privateProperties.forEach(prop => {
            if (prop.key === key) {
                propertyValue = prop.value;
            }
        });
    }
    return propertyValue;
};
exports.getPropertyValue = getPropertyValue;
